<template>
  <div class="social-sharing">
    <fade-transition>
      <a-visibility v-if="isSocialListOpened" show :on="[$breakpoint.mobile]">
        <div class="social-sharing__overlay" />
      </a-visibility>
    </fade-transition>

    <collapse-transition>
      <div
        v-if="isSocialListOpened"
        v-on-clickaway="closeSocialList"
        class="social-sharing__container"
        :class="containerClass"
      >
        <div class="social-sharing__features">
          <span class="social-sharing__title hide-on-desktop">Share</span>
          <div class="social-sharing__close-container">
            <div
              class="social-sharing__close-icon close-icon close-icon_sm"
              :class="closeIconClass"
              @click="closeSocialList"
            />
          </div>
        </div>

        <a-social-sharing
          v-for="social in socialSharingList"
          :key="social.icon"
          class="social-sharing__icon-container"
          hashtags="forexlive"
          twitter-user="forexlive"
          :url="sharingUrl"
          :network="social.network"
          :title="getTitleBySocialType(social.network)"
          :description="getDescriptionBySocialType(social.network)"
          :quote="title"
          @open="closeSocialList"
          :open-in-iframe="openInIframe"
        >
          <a-icon
            is-button
            tabindex="-1"
            class="social-icon"
            :title="social.title"
            :width="social.size"
            :height="social.size"
            :color="social.color"
            :original="social.original"
            :icon="social.icon"
            :aria-label="social.ariaLabel"
          />
        </a-social-sharing>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

import { ICON, ICON_COLOR } from 'shared/AIcon'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import socialSharing from '../../socialSharing'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { NETWORK } from './enums'

const NETWORK_LIST = Object.values(NETWORK)

const SOCIAL_SHARING_DESKTOP = {
  [NETWORK.TWITTER]: { icon: ICON.TWITTER, title: 'Twitter' },
  [NETWORK.LINKED_IN]: { icon: ICON.LINKEDIN, title: 'LinkedIn' },
  [NETWORK.TELEGRAM]: { icon: ICON.TELEGRAM, title: 'Telegram' },
  [NETWORK.FACEBOOK]: { icon: ICON.FACEBOOK, title: 'Facebook' },
  [NETWORK.WHATS_APP]: { icon: ICON.WHATSUP_ROUND_GREEN, title: 'WhatsApp' },
  [NETWORK.EMAIL]: { icon: ICON.ENVELOPE_2, title: 'Email' },
  [NETWORK.REDDIT]: { icon: ICON.REDDIT_ROUND_ORANGE, title: 'Reddit' }
}

const SOCIAL_SHARING_MOBILE = {
  [NETWORK.TWITTER]: { icon: ICON.TWITTER_ROUND_BLUE, title: 'Twitter' },
  [NETWORK.LINKED_IN]: { icon: ICON.LINKEDIN_ROUND_BLUE, title: 'LinkedIn' },
  [NETWORK.TELEGRAM]: { icon: ICON.TELEGRAM_ROUND_BLUE, title: 'Telegram' },
  [NETWORK.FACEBOOK]: { icon: ICON.FACEBOOK_ROUND_BLUE, title: 'Facebook' },
  [NETWORK.WHATS_APP]: { icon: ICON.WHATSUP_ROUND_GREEN, title: 'WhatsApp' },
  [NETWORK.EMAIL]: { icon: ICON.ENVELOPE_ROUND_GRAY, title: 'Email' },
  [NETWORK.REDDIT]: { icon: ICON.REDDIT_ROUND_ORANGE, title: 'Reddit' }
}

export default {
  name: 'SocialSharingList',
  directives: { onClickaway },
  mixins: [socialSharing, hydrationHelpers],
  model: {
    prop: 'isSocialListOpened'
  },
  props: {
    isSocialListOpened: propValidator([PROP_TYPES.BOOLEAN]),
    heightAuto: propValidator([PROP_TYPES.BOOLEAN]),
    networkList: propValidator(
      [PROP_TYPES.ARRAY],
      false,
      () => NETWORK_LIST,
      arr => arr.every(network => NETWORK_LIST.includes(network))
    ),
    networkListMobile: propValidator(
      [PROP_TYPES.ARRAY],
      false,
      () => NETWORK_LIST,
      arr => arr.every(network => NETWORK_LIST.includes(network))
    ),
    openInIframe: propValidator([PROP_TYPES.BOOLEAN])
  },
  computed: {
    socialSharingList() {
      const isMobile = this.$_hydrationHelpers_isLayoutMobile
      const networkList = isMobile ? this.networkListMobile : this.networkList
      const networkProps = isMobile
        ? SOCIAL_SHARING_MOBILE
        : SOCIAL_SHARING_DESKTOP
      const styleProps = isMobile
        ? { size: 25, original: true }
        : { size: 18, original: false, color: ICON_COLOR.WHITE }

      return networkList.map(network => ({
        ...networkProps[network],
        ...styleProps,
        network,
        ariaLabel: network
      }))
    },
    closeIconClass() {
      return { 'close-icon_white': !this.$_hydrationHelpers_isLayoutMobile }
    },
    containerClass() {
      return { 'social-sharing__container_auto-height': this.heightAuto }
    }
  },
  methods: {
    closeSocialList() {
      this.$emit('input', false)
    },
    getTitleBySocialType(type) {
      if (type === NETWORK.EMAIL && this.emailTitle) {
        return this.emailTitle
      }

      return this.title
    },
    getDescriptionBySocialType(type) {
      if (type === NETWORK.EMAIL && this.emailDescription) {
        return this.emailDescription
      }

      return this.description
    }
  }
}
</script>

<style lang="scss" scoped>
$mobile-padding-left: 30px;
$mobile-title-height: 44px;

$social-sharing-gap: 16px;

$overlay-z-index: 1001;
$container-z-index: $overlay-z-index + 1;

.social-sharing {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 263px;
    padding: 25px 20px 20px;
    background-color: $c--main;
    z-index: 2;

    /deep/ .a-icon__description {
      &::before {
        width: 115px;
      }
    }

    @include mobile {
      display: block;
      position: fixed;
      height: auto;
      width: 100%;
      top: auto;
      bottom: 0;
      padding: 0;
      border: 2px solid $c--main;
      background-color: white;
      z-index: $container-z-index;
    }

    /deep/ .social-icon {
      flex-direction: row;
      width: 100%;

      .a-icon__icon {
        margin-right: 10px;
      }

      .a-icon__description {
        margin-top: 0;
        font-size: 14px;
        font-weight: 600;
      }

      .a-icon__description::before {
        margin: 0;
        text-align: left;
      }
    }

    &_auto-height {
      height: auto;
    }
  }

  &__icon-container {
    display: flex;
    align-items: flex-start;
    width: calc(50% - #{$social-sharing-gap / 2});
    margin-bottom: $social-sharing-gap;

    &:nth-last-child(1),
    &:nth-last-child(2) {
      margin-bottom: 0;
    }

    @include mobile {
      width: 100%;
      margin: 0;
      padding: 10px $mobile-padding-left;
      border-bottom: 2px solid #ebebeb;
    }
  }

  &__title {
    font-size: 19px;
    font-family: $font-heading;
    font-weight: bold;
    line-height: 20px;
  }

  &__features {
    position: absolute;
    top: 0;
    right: 0;

    @include mobile {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: $mobile-title-height;
      padding: 10px 10px 10px $mobile-padding-left;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &__close-container {
    position: absolute;
    background-color: black;
    top: 0;
    right: 0;
    width: 22px;
    height: 22px;

    /deep/ .close-icon {
      top: 3px;
      left: 3px;
    }

    @include mobile {
      position: relative;
      background-color: transparent;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $overlay-z-index;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
</style>
