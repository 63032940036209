<template>
  <a-form
    ref="form"
    :form-type="$options.consts.FORM_TYPE.CONTACT_COMPANY"
    :key="prefilledForm_reRenderKey"
    :is-term-accepted.sync="isTermAccepted"
    :external-settings="contactFormSettings"
    :captcha-key.sync="captchaKey"
    :form-validation-fn="$_componentValidation_isFormValid"
    submit-button-text="Send your request"
    :errors="$v"
    use-default-gdpr
    :form-id="formId"
    class="company-contact-form__wrapper"
    @submit="onSubmit"
  >
    <div class="company-contact-form__field-wrapper">
      <a-text-input
        v-model="userForm.FullName"
        :focus-on-mount="focusOnMount"
        type="text"
        class="company-contact-form__field half-size"
        placeholder="Name"
        required
        :fluid="false"
        :error="$v.userForm.FullName"
        prefilled-mode
      />
      <a-text-input
        v-model="userForm.Email"
        type="email"
        class="company-contact-form__field half-size"
        placeholder="Email"
        required
        :fluid="false"
        :error="$v.userForm.Email"
        prefilled-mode
      />
      <a-text-input
        v-model="userForm.Phone"
        type="tel"
        class="company-contact-form__field half-size phone"
        placeholder="Phone"
        required
        :fluid="false"
        :error="$v.userForm.Phone"
      />
      <a-text-input
        v-model="userForm.Message"
        type="text"
        class="company-contact-form__field message"
        placeholder="Write your request"
        multiline
        :fluid="false"
      />
    </div>
  </a-form>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { prop } from 'ramda'

import { propValidator, PROP_TYPES } from '@/utils/validators'
import mixins from '@/utils/mixins'
import { phone } from '@fmpedia/validators'
import ATextInput from '@/components/_shared/ATextInput'
import AForm, { FORM_TYPE } from 'shared/AForm'

export default {
  name: 'CompanyContactForm',
  mixins: [
    mixins.validation,
    mixins.componentValidation,
    mixins.prefilledForm(FORM_TYPE.CONTACT_COMPANY)
  ],
  components: { ATextInput, AForm },
  props: {
    CompanyName: propValidator([PROP_TYPES.STRING]),
    Gdpr: propValidator([PROP_TYPES.STRING]),
    Id: propValidator([PROP_TYPES.STRING]),
    closeOnSubmit: propValidator([PROP_TYPES.FUNCTION], false, () => () => {}),
    focusOnMount: propValidator([PROP_TYPES.BOOLEAN], false, false),
    formId: propValidator([PROP_TYPES.STRING], false)
  },
  consts: {
    FORM_TYPE,
    resetComponentDataFields: ['isTermAccepted', 'userForm', 'captchaKey']
  },
  data() {
    return {
      isTermAccepted: false,
      userForm: this.$_prefilledForm_getPrefilledModel(),
      captchaKey: ''
    }
  },
  computed: {
    contactFormSettings() {
      return {
        Title: `Contact ${this.CompanyName} directly`,
        TopText:
          'Contact us directly for further inquiries about our products and services.',
        Gdpr: this.Gdpr,
        SuccessMessageText:
          'Your message has been sent to the relevant company',
        SuccessMessageTitle: 'Thank you!'
      }
    }
  },
  validations: {
    userForm: {
      FullName: { required },
      Email: { required, email },
      Phone: { required, phone }
    },
    isTermAccepted: {
      required: value => !!value
    }
  },
  methods: {
    prop,
    ...mapActions({
      sendRequestToCompany: 'companyProfile/sendRequestToCompany',
      checkIfForbiddenContactCompanyEmailDomain:
        'companyProfile/checkIfForbiddenContactCompanyEmailDomain'
    }),
    async onSubmit() {
      if (this.$helper.isValidationFailed(this)) return

      const { FullName, Email, Phone, Message } = this.userForm

      const emailDomain = this.$helper.getEmailDomain(Email)
      const isForbiddenEmailDomain = await this.checkIfForbiddenContactCompanyEmailDomain(
        { emailDomain }
      )

      if (isForbiddenEmailDomain) {
        await this.handleSuccessfulFormSubmission()
        return
      }

      try {
        const payload = {
          Name: FullName,
          Email,
          Phone,
          Message,
          CompanyId: this.Id
        }
        await this.sendRequestToCompany({
          captchaKey: this.captchaKey,
          payload
        })
        await this.handleSuccessfulFormSubmission()
      } catch (err) {
        console.log(err)
      }
    },
    async handleSuccessfulFormSubmission() {
      await this.$refs.form.showSuccessMessage()
      this.clearForm()
      this.closeOnSubmit()
    },
    clearForm() {
      this.$helper.resetComponentData(
        this,
        this.$options.consts.resetComponentDataFields
      )
      this.$v.$reset()
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.company-contact-form__wrapper {
  /deep/ .form__top-text {
    @include mobile {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  /deep/ .form__submit-button {
    width: 160px;

    @include tablet {
      margin-top: 0;
    }

    @include mobile {
      width: 100%;
    }
  }

  /deep/ .form__row-gdpr {
    @include tablet {
      margin-bottom: 20px;
    }

    .form__gdpr {
      @include tablet {
        margin-top: 20px;
      }

      @include mobile {
        font-size: 11px;
      }
    }
  }

  .company-contact-form__field-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -18px;

    @include tablet {
      width: 100%;
      margin: 0;
      flex-direction: column;
    }
  }

  .company-contact-form__field {
    margin: 25px 18px 0;
    width: calc(100% - 36px);

    /* first two inputs */
    &:nth-child(-n + 2) {
      margin-top: 0;
    }

    &:nth-child(2) {
      @include tablet {
        margin-top: 35px;
      }
    }

    @include tablet {
      width: 100%;
      margin: 35px 0 0;
    }

    &.half-size {
      width: calc(50% - 36px);

      @include tablet {
        width: 100%;
      }
    }

    &.phone /deep/ .input-group__error {
      width: calc(200% + 36px);

      @include tablet {
        width: 100%;
      }
    }

    &.message {
      margin-top: 40px;

      @include tablet {
        margin-top: 35px;
      }
    }
  }
}
</style>
