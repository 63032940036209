<template>
  <a-icon
    v-bind="$attrs"
    :id="wrapperId"
    :icon="$attrs.icon || ICON.SAVE_ROUND_BLACK"
    :is-button="!$attrs.to"
    :title="title"
    :title-on-hover="titleOnHover"
    :disabled="!id || inProgress"
    @click="onClick"
  />
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import mixins from '@/utils/mixins'
import AIcon, { ICON } from 'shared/AIcon'
import {
  METHOD_NAME,
  PENDING_ACTION,
  PENDING_ACTION_EVENT
} from 'enums/pending-actions'

export default {
  name: 'SaveForLater',
  inheritAttrs: false,
  mixins: [
    mixins.pendingAuthActions,
    mixins.pendingAuthActionsListenersFactory({
      [PENDING_ACTION_EVENT.START_PENDING_ACTION]:
        METHOD_NAME.HANDLE_START_PENDING_ACTION,
      [PENDING_ACTION_EVENT.END_PENDING_ACTION]:
        METHOD_NAME.HANDLE_END_PENDING_ACTION
    })
  ],
  components: { AIcon },
  props: {
    id: propValidator([PROP_TYPES.STRING], false),
    isSaved: propValidator([PROP_TYPES.BOOLEAN])
  },
  data() {
    return { ICON, inProgress: false, guid: this.$helper.guid() }
  },
  computed: {
    wrapperId() {
      return `${this.$options.name}-${this.guid}`
    },
    title() {
      return this.isSaved ? 'Saved' : 'Save'
    },
    titleOnHover() {
      return this.isSaved ? 'Unsave' : ''
    }
  },
  methods: {
    onClick() {
      const actionData = { id: this.id, newValue: !this.isSaved }
      this.$_pendingAuthActions_handleAction({
        actionType: PENDING_ACTION.SAVE_ARTICLE,
        actionData,
        activatorSelector: `#${this.wrapperId}`
      })
    },
    [METHOD_NAME.HANDLE_START_PENDING_ACTION](refreshPayload) {
      if (refreshPayload.id === this.id) {
        this.inProgress = true
      }
    },
    [METHOD_NAME.HANDLE_END_PENDING_ACTION](refreshPayload) {
      if (refreshPayload.id === this.id) {
        this.inProgress = false
      }
    }
  }
}
</script>
