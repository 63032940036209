<template>
  <component :is="actionIcon" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import Share from './Share'
import Twitter from './Twitter'
import Telegram from './Telegram'
import Facebook from './Facebook'
import WhatsApp from './WhatsApp'
import LinkedIn from './LinkedIn'
import Reddit from './Reddit'
import Email from './Email'
import FollowTag from './FollowTag'
import SaveForLater from './SaveForLater'
import FlCommentCount from './FlCommentCount'
import ContactCompany from './ContactCompany'
import CompanyProfile from './CompanyProfile'
import { PROP_TYPES, propValidator } from '@/utils/validators'

export const ACTION_ICON = {
  SAVE: 'SaveForLater',
  FOLLOW: 'FollowCompany',
  FOLLOW_TAG: 'FollowTag',
  SHARE: 'Share',
  LINKED_IN: 'LinkedIn',
  TWITTER: 'Twitter',
  TELEGRAM: 'Telegram',
  FACEBOOK: 'Facebook',
  WHATSAPP: 'WhatsApp',
  REDDIT: 'Reddit',
  EMAIL: 'Email',
  FL_COMMENT_COUNT: 'FlCommentCount',
  LEARN_ABOUT: 'LearnAboutTerm',
  COMPANY_PROFILE: 'CompanyProfile',
  CONTACT_COMPANY: 'ContactCompany'
}

const ACTION_ICON_LIST = Object.values(ACTION_ICON)

export default {
  name: 'AActionIcon',
  inheritAttrs: false,
  components: {
    Twitter,
    Telegram,
    Facebook,
    WhatsApp,
    LinkedIn,
    Reddit,
    Email,
    FollowTag,
    SaveForLater,
    FlCommentCount,
    ContactCompany,
    CompanyProfile,
    Share
  },
  props: {
    actionIcon: propValidator([PROP_TYPES.STRING], true, null, prop =>
      ACTION_ICON_LIST.includes(prop)
    )
  }
}
</script>
