<template>
  <a-icon
    v-bind="$attrs"
    :icon="$attrs.icon || ICON.MORE_INFO_ROUND_BLUE"
    :title="title"
    :color="ICON_COLOR.BLUE"
    :disabled="!company.Slug || !company.CategorySlug"
    :to="companyUrl"
    :trailing-slash="$helper.isLinkWithTrailingSlash(companyUrl)"
  />
</template>

<script>
import AIcon, { ICON, ICON_COLOR } from 'shared/AIcon'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import mixins from '@/utils/mixins'

export default {
  name: 'CompanyProfile',
  mixins: [mixins.urlGenerators],
  inheritAttrs: false,
  components: { AIcon },
  props: {
    company: propValidator([PROP_TYPES.OBJECT])
  },
  data() {
    return { ICON, ICON_COLOR }
  },
  computed: {
    title() {
      return `More info`
    },
    companyUrl() {
      return this.generateCompanyUrl(this.company)
    }
  }
}
</script>
