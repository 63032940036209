<template>
  <div class="contact-company">
    <a-icon
      v-bind="$attrs"
      :id="contactCompanyActivatorId"
      is-button
      :icon="$attrs.icon || ICON.ENVELOPE_ROUND_BLACK"
      @click="onClick"
    />

    <a-modal-widget
      v-model="isCompanyContactUsFormVisible"
      :activator-selector="contactCompanyActivatorSelector"
      :close-on-path-change="false"
      :width="645"
      :scrollTop="-181"
      :show-social-networks="false"
    >
      <a-company-contact-form
        focus-on-mount
        class="contact-company__form"
        v-bind="companyContactUsProps"
        slot-scope="{ closeModal }"
        :closeOnSubmit="closeModal"
        :form-id="$options.consts.FORM_ID.COMPANY_CONTACT_US"
      />
    </a-modal-widget>
  </div>
</template>

<script>
import AIcon, { ICON } from 'shared/AIcon'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import ACompanyContactForm from 'shared/ACompanyContactForm'
import { FORM_ID } from 'enums/form-id'

export default {
  name: 'ContactCompany',
  inheritAttrs: false,
  components: {
    AModalWidget: () => import('@/components/_modals/AModalWidget'),
    AIcon,
    ACompanyContactForm
  },
  props: {
    id: propValidator([PROP_TYPES.STRING]),
    name: propValidator([PROP_TYPES.STRING]),
    gdpr: propValidator([PROP_TYPES.STRING])
  },
  consts: {
    FORM_ID
  },
  data() {
    return {
      ICON,
      isCompanyContactUsFormVisible: false,
      guid: this.$helper.guid()
    }
  },
  computed: {
    contactCompanyActivatorId() {
      return `${this.$options.name}-${this.guid}`
    },
    contactCompanyActivatorSelector() {
      return `#${this.contactCompanyActivatorId}`
    },
    companyContactUsProps() {
      return {
        Id: this.id,
        CompanyName: this.name,
        Gdpr: this.gdpr
      }
    }
  },
  methods: {
    onClick() {
      this.isCompanyContactUsFormVisible = true
    }
  }
}
</script>
<style lang="scss">
.contact-company {
  position: relative;
  margin-top: 40px;

  @include mobile {
    margin-top: 0;
  }

  .contact-company__form {
    padding: 60px 40px 40px;
  }
}
</style>
