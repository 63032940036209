<template>
  <comment-count
    v-bind.sync="$attrs"
    :domain-url="$env.DOMAIN_URL"
    :shortname="$env.FL_DISQUS_SHORT_NAME"
    @update:isCommentCounterVisible="
      $emit('update:isCommentCounterVisible', $event)
    "
  />
</template>

<script>
import CommentCount from '../_shared/CommentCount'

export default {
  name: 'FlCommentCount',
  components: { CommentCount }
}
</script>
