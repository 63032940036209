import AIcon, { ICON, ICON_COLOR } from 'shared/AIcon'
import ASocialSharing from 'shared/ASocialSharing'
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  inheritAttrs: false,
  props: {
    path: propValidator([PROP_TYPES.STRING]),
    customLink: propValidator([PROP_TYPES.STRING], false, ``),
    title: propValidator([PROP_TYPES.STRING]),
    description: propValidator([PROP_TYPES.STRING], false),
    openInIframe: propValidator([PROP_TYPES.BOOLEAN], false, true),
    emailTitle: propValidator([PROP_TYPES.STRING], false, null),
    emailDescription: propValidator([PROP_TYPES.STRING], false, null)
  },
  components: { AIcon, ASocialSharing },
  data() {
    return { ICON, ICON_COLOR }
  },
  computed: {
    sharingUrl() {
      if (this.customLink) return this.customLink

      return `${this.$env.DOMAIN_URL}${this.path}`
    }
  }
}
