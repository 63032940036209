<template>
  <a-social-sharing
    hashtags="forexlive"
    twitter-user="forexlive"
    network="reddit"
    :url="sharingUrl"
    :title="title"
    :description="description"
    :quote="title"
    :open-in-iframe="openInIframe"
  >
    <a-icon
      v-bind="$attrs"
      is-button
      tabindex="-1"
      :icon="$attrs.icon || ICON.REDDIT_ROUND_BLACK"
      fill="black"
    />
  </a-social-sharing>
</template>

<script>
import socialSharing from '../socialSharing'

export default {
  name: 'Reddit',
  mixins: [socialSharing]
}
</script>
