<template>
  <div class="social-sharing" :class="wrapperClass">
    <a-icon
      v-bind="$attrs"
      :icon="$attrs.icon || ICON.SHARE"
      is-button
      @click="onClick"
    />

    <social-sharing-list
      v-model="isSocialListOpened"
      :custom-link="customLink"
      :title="title"
      :path="path"
      :description="description"
      :email-title="emailTitle"
      :email-description="emailDescription"
      :height-auto="heightAuto"
      :network-list="networkList"
      :open-in-iframe="openInIframe"
    />
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import SocialSharingList from './SocialSharingList'
import AIcon, { ICON } from 'shared/AIcon'

export default {
  name: 'Share',
  inheritAttrs: false,
  components: { AIcon, SocialSharingList },
  props: {
    path: propValidator([PROP_TYPES.STRING]),
    customLink: propValidator([PROP_TYPES.STRING], false, ``),
    title: propValidator([PROP_TYPES.STRING]),
    description: propValidator([PROP_TYPES.STRING], false, ''),
    emailTitle: propValidator([PROP_TYPES.STRING], false, null),
    emailDescription: propValidator([PROP_TYPES.STRING], false, null),
    heightAuto: propValidator([PROP_TYPES.BOOLEAN], false, false),
    networkList: propValidator([PROP_TYPES.ARRAY], false),
    networkListMobile: propValidator([PROP_TYPES.ARRAY], false),
    openInIframe: propValidator([PROP_TYPES.BOOLEAN], false, true)
  },
  data() {
    return {
      ICON,
      isSocialListOpened: false
    }
  },
  computed: {
    wrapperClass() {
      return {
        'social-sharing__relative': this.heightAuto
      }
    }
  },
  methods: {
    onClick() {
      this.isSocialListOpened = true
    }
  }
}
</script>

<style lang="scss" scoped>
.social-sharing {
  &__relative {
    position: relative;
  }
}
</style>
