<template>
  <div class="comment-count__wrapper" :class="dynamicClass">
    <a-icon
      v-bind="$attrs"
      is-button
      :icon="iconWithDefault"
      :tabindex="iconTabindex"
      :width="35"
      :height="35"
      class="comment-count__icon"
      :class="dynamicClass"
      @click="redirectToLink"
    >
      <span slot="additional-info" class="comment-icon__count">
        {{ commentCount }}
      </span>
    </a-icon>
    <span
      ref="counter"
      class="disqus-comment-count"
      :data-disqus-identifier="identifier"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AIcon, { ICON } from 'shared/AIcon'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import {
  MIN_COMMENT_COUNT_TO_SHOW_COUNTER,
  DISQUS_HASH,
  DISQUS_SCROLL_OFFSET,
  DISQUS_SCROLL_DELAY,
  UPDATE_COMMENT_COUNT_EVENT
} from 'shared/AActionIcon/_shared/CommentCount/enums'

export default {
  name: 'CommentCount',
  inheritAttrs: false,
  components: { AIcon },
  props: {
    identifier: propValidator([PROP_TYPES.STRING]),
    isCommentCounterVisible: propValidator([PROP_TYPES.BOOLEAN], false, false),
    minCommentCountToShowCounter: propValidator(
      [PROP_TYPES.NUMBER],
      false,
      MIN_COMMENT_COUNT_TO_SHOW_COUNTER
    ),
    link: propValidator([PROP_TYPES.STRING, PROP_TYPES.OBJECT], false),
    initialCommentCount: propValidator([PROP_TYPES.NUMBER], false, 0),
    domainUrl: propValidator([PROP_TYPES.STRING]),
    shortname: propValidator([PROP_TYPES.STRING])
  },
  data() {
    return {
      commentCount: this.initialCommentCount
    }
  },
  computed: {
    ...mapGetters({
      isDisqusWidgetLoaded: 'isDisqusWidgetLoaded'
    }),
    iconTabindex() {
      return this.isEnoughCommentsToShowCounter ? 0 : -1
    },
    iconWithDefault() {
      return this.$attrs.icon || ICON.COMMENT_COUNT_ROUND_BLACK
    },
    commentLink() {
      const defaultLink = `${this.domainUrl}${this.$route.path}${DISQUS_HASH}`

      return this.link || defaultLink
    },
    isEnoughCommentsToShowCounter() {
      return this.commentCount >= this.minCommentCountToShowCounter
    },
    dynamicClass() {
      return {
        visible: this.isEnoughCommentsToShowCounter
      }
    }
  },
  watch: {
    isEnoughCommentsToShowCounter: {
      handler(newVal) {
        this.$emit('update:isCommentCounterVisible', newVal)
      }
    }
  },
  methods: {
    getCommentCountValue() {
      const counter = this.$refs.counter
      if (!counter) return

      const counterContent = counter.innerHTML
      const newCommentCount = counterContent ? parseInt(counterContent) : 0

      if (newCommentCount === this.commentCount) return

      this.commentCount = newCommentCount
      this.$emit('update', this.commentCount)
    },
    /**
     * We should handle link to the article by javascript and use "is-button" prop,
     * because in other case Disqus parses html tags by link and replace all content with
     * comment count (replaces AIcon component as well)
     */
    redirectToLink() {
      const resolvedLink = this.$router.resolve(this.commentLink).resolved

      if (this.$route.name === resolvedLink.name) {
        this.$scrollTo(DISQUS_HASH, DISQUS_SCROLL_DELAY, {
          offset: DISQUS_SCROLL_OFFSET
        })
      } else {
        window.open(this.commentLink, '_blank')
      }
    },
    disqusLoadingPromise() {
      return new Promise((resolve, reject) => {
        this.$helper.watchAndExecuteOnce({
          ctx: this,
          field: 'isDisqusWidgetLoaded',
          conditionFn: newVal => !!newVal,
          handlerFn: resolve,
          timeoutFn: reject,
          immediate: true
        })
      })
    },
    startListeningCommentCounterEvents() {
      this.$bus.$on(UPDATE_COMMENT_COUNT_EVENT, this.getCommentCountValue)
    },
    stopListeningCommentCounterEvents() {
      this.$bus.$off(UPDATE_COMMENT_COUNT_EVENT)
    }
  },
  async mounted() {
    try {
      await this.disqusLoadingPromise()
      this.startListeningCommentCounterEvents()
    } catch (err) {
      console.error('Disqus was never loaded')
    }

    this.startListeningCommentCounterEvents()
  },
  beforeDestroy() {
    this.stopListeningCommentCounterEvents()
  }
}
</script>

<style lang="scss" scoped>
.comment-count__wrapper {
  position: relative;
  display: inline-block;
  width: 0;
  height: 35px;
  transition: width 0.5s ease-in-out;
  overflow: hidden;

  &.visible {
    width: 35px;
  }

  .comment-count__icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.comment-icon__count {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-sans;
  font-size: 10px;
  font-weight: 700;
  transition: none;
}

.disqus-comment-count {
  display: inline-block;
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>
