<template>
  <a-icon
    v-if="tag"
    v-bind="$attrs"
    :id="wrapperId"
    :icon="$attrs.icon || ICON.WALKING_FINGERS_ROUND_BLACK"
    :is-button="!$attrs.to"
    :title="title"
    :title-on-hover="titleOnHover"
    :disabled="!tag.id || inProgress"
    @click="onClick"
  />
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import mixins from '@/utils/mixins'
import AIcon, { ICON } from 'shared/AIcon'
import {
  METHOD_NAME,
  PENDING_ACTION,
  PENDING_ACTION_EVENT
} from 'enums/pending-actions'

export default {
  name: 'FollowTag',
  inheritAttrs: false,
  mixins: [
    mixins.pendingAuthActions,
    mixins.pendingAuthActionsListenersFactory({
      [PENDING_ACTION_EVENT.START_PENDING_ACTION]:
        METHOD_NAME.HANDLE_START_PENDING_ACTION,
      [PENDING_ACTION_EVENT.END_PENDING_ACTION]:
        METHOD_NAME.HANDLE_END_PENDING_ACTION
    })
  ],
  components: { AIcon },
  props: {
    tag: propValidator([PROP_TYPES.OBJECT], false)
  },
  data() {
    return { ICON, inProgress: false, guid: this.$helper.guid() }
  },
  computed: {
    wrapperId() {
      return `${this.$options.name}-${this.guid}`
    },
    title() {
      const prefix = this.tag.isFollowed ? 'Following' : 'Follow'
      return `${prefix} ${this.tag.name}`
    },
    titleOnHover() {
      const prefix = this.tag.isFollowed ? 'Unfollow' : 'Follow'
      return `${prefix} ${this.tag.name}`
    }
  },
  methods: {
    onClick() {
      const actionData = { id: this.tag.id, newValue: !this.tag.isFollowed }
      this.$_pendingAuthActions_handleAction({
        actionType: PENDING_ACTION.FOLLOW_TAG,
        actionData,
        activatorSelector: `#${this.wrapperId}`
      })
    },
    [METHOD_NAME.HANDLE_START_PENDING_ACTION](refreshPayload) {
      if (refreshPayload.id === this.tag.id) {
        this.inProgress = true
      }
    },
    [METHOD_NAME.HANDLE_END_PENDING_ACTION](refreshPayload) {
      if (refreshPayload.id === this.tag.id) {
        this.inProgress = false
      }
    }
  }
}
</script>
